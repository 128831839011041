import React, { useEffect, useState } from "react"
import './header.sass'
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const headerStyle = { backgroundColor: `rgba(222, 242, 241, ${1})`, boxShadow: `0 2px 15px 0 rgba(0,0,0,${1 / 5})` }

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // const [navOpacity, setNavOpacity] = useState(0);
  //
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 400) {
  //       setNavOpacity(1);
  //       return;
  //     }
  //
  //     setNavOpacity(window.scrollY === 0 ? 0 : window.scrollY / 400);
  //   }
  //
  //   document.addEventListener('scroll', handleScroll);
  //
  //   return () => {
  //     document.removeEventListener('scroll', handleScroll)
  //   }
  // }, []);

  return (
    <div className='header'>
      <div className='logo'>
        <AnchorLink to='/#hero'>
          <Img fluid={data.image.childImageSharp.fluid} style={{ width: '15rem', objectFit: 'contain' }} />
        </AnchorLink>
      </div>
      <div className='navigation'>
        <AnchorLink className='nav-item' to='/#about'>
          Over ons
          <span className='border first' />
        </AnchorLink>
        <AnchorLink className='nav-item' to='/#aanbod'>
          Aanbod
          <span className='border' />
        </AnchorLink>
        <AnchorLink className='nav-item' to='/#contact'>
          Contact
          <span className='border last' />
        </AnchorLink>
      </div>
    </div>
  )
}

export default Header
