import React, { useEffect } from "react"
import Heading from "../heading/heading"
import './contactNew.sass'
import { IoLocationSharp, MdEmail, MdPhone } from "react-icons/all"

const ContactNew = () => {
  const styleIframe = () => {
    // document.querySelector('.place-card').style.display = 'none';
  }

  return (
    <section id='contact' className='contact-new'>
      <Heading text='Contact' />

      <div className='contact-content'>
        <div className='contact-details'>
          <div className='contact-details-content'>
            <span className='contact-line'>
                <MdPhone />
                <span className='contact-info'>
                  <a href='tel:+32495497757'>+32 495 49 77 57</a>
                </span>
              </span>
            <span className='contact-line'>
                <MdEmail />
                <span className='contact-info'>
                  <a href='mailto:gertjanvanbavelboomkwekerij@gmail.com'>gertjanvanbavelboomkwekerij@gmail.com</a>
                </span>
              </span>
            <span className='appointment'>Een bezoek aan de boomkwekerij is mogelijk na afspraak</span>
            <a href='mailto:gertjanvanbavelboomkwekerij@gmail.com' className='action contact-button'>
              <span>E-mail</span>
            </a>
          </div>
        </div>

        <div className='contact-map'>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2489.0728354962516!2d4.723335816152817!3d51.40171637961801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6a814e0fdcfcb%3A0xc2215e529b8d974b!2sHinnenboomstraat%207%2C%202320%20Hoogstraten!5e0!3m2!1sen!2sbe!4v1616528887587!5m2!1sen!2sbe"
            width="100%"
            height="450"
            style={{border:0}}
            allowFullScreen=""
            loading="lazy"
            onLoad={styleIframe}
          >
          </iframe>
        </div>
      </div>
    </section>
  )
}

export default ContactNew
