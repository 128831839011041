import React from "react"
import './footer.sass'

const Footer = () => {
  return (
    <footer className='footer'>
      <div>
        &copy; {(new Date()).getFullYear()} Gertjan Van Bavel Boomkwekerij
      </div>
      <div>
        <a className='created-by' href='https://www.syntactful.com' target='_blank'>Website door Syntactful</a>
      </div>
    </footer>
  )
}

export default Footer
