import React from 'react'
import './hero.sass'
import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { AiFillEye, IoIosArrowRoundDown, MdEmail } from "react-icons/all"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "atmosphere/Boomkwekerij_Gertjan_Van_Bavel_2020_022.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2048, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id='hero' className='hero'>
      <Img fluid={data.image.childImageSharp.fluid} style={{ height: '100vh' }} />
      <div className='tagline'>
        <span className='tagline-1'>Gertjan Van Bavel Boomkwekerij</span>
        <span className='tagline-2'>Taxus - Ilex - Osmanthus - Buxus</span>

        <div className='actions'>
          <AnchorLink className='action' to='/#aanbod'>
            <span>Bekijk aanbod</span>
          </AnchorLink>
          <a href='mailto:gertjanvanbavelboomkwekerij@gmail.com' className='action'>
            <span>E-mail ons</span>
          </a>
        </div>
      </div>

      <div className='scroll'>
        <AnchorLink to='/#about' className='scroll-action'>
          <IoIosArrowRoundDown />
        </AnchorLink>
      </div>
    </section>
  )
}

export default Hero
