import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Aanbod from "../components/aanbod/aanbod"
import Header from "../components/header/header"
import About from "../components/about/about"
import Contact from "../components/contact/contact"
import Footer from "../components/footer/footer"
import Hero from "../components/hero/hero"
import ContactNew from "../components/contactNew/contactNew"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Header />
    <Hero />
    <About />
    <Aanbod />
    <ContactNew />
    <Footer />

  </Layout>
)

export default IndexPage
