import React from "react"
import './about.sass'
import Heading from "../heading/heading"

const About = () => {
  return (
    <section id='about' className='about'>
      <Heading text='Over het bedrijf' />

      <div className='about-card'>
        <p>
          Reeds op zeer jonge leeftijd kreeg ik de passie voor
          planten van mijn ouders mee. Zij hadden een eigen
          boomkwekerij waar ik elk vrij moment te vinden was. De
          inspiratie en kennis die ik vanaf toen mee kreeg, hielp
          mij om in 2008 de beslissing te nemen om mijn eigen
          boomkwekerij te starten.
        </p>
        <p>
          Ondertussen is Boomkwekerij Gertjan Van Bavel
          gespecialiseerd in groenblijvende heesters. Voornamelijk
          buxus, taxus, ilex en osmanthus worden met veel zorg
          onderhouden want kwaliteit primeert in dit bedrijf.
          U kan hier terecht voor hagen in alle formaten,
          snoeivormen en wolkplanten.
        </p>
        <p>
          Door de nadruk te leggen op een assortiment dat met
          veel vakmanschap en passie wordt onderhouden,
          worden de planten zowel nationaal als internationaal
          verkocht.
        </p>
      </div>
    </section>
  )
}

export default About
