import React, { useEffect, useState } from "react"
import './modal.sass'
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClose, BsGrid1X2 } from "react-icons/all"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Modal = ({ isVisible, folder, close }) => {
  const [index, setIndex] = useState(0);
  const [isThumbnailsVisible, setIsThumbnailsVisible] = useState(true);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {in: ["taxus", "buxus", "ilex/aquifolium", "ilex/blue_prince", "ilex/blue_princess", "ilex/crenate", "osmanthus"]}}) {
        edges {
          node {
            base
            relativeDirectory
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setIsThumbnailsVisible(false);
    }
  }, []);

  const images = folder
    ? data.allFile.edges.filter(({ node }) => {
      return node.relativeDirectory.split('/')[0] === folder;
    })
    : data.allFile.edges

  const handleArrowClick = (type) => {
    switch (type) {
      case 'next':
        const nextIndex = index + 1;
        if (nextIndex >= images.length) {
          setIndex(0);
        } else {
          setIndex(nextIndex);
        }

        break;
      case 'previous':
        const previousIndex = index - 1;
        if (previousIndex < 0) {
          setIndex(images.length - 1);
        } else {
          setIndex(previousIndex);
        }

        break;
      default:
        return;
    }
  }

  return (
    <div className={`modal ${isVisible ? 'modal-active' : ''}`}>
      <div className='modal-header'>
          <span className='icon icon-grid' onClick={() => setIsThumbnailsVisible(!isThumbnailsVisible)}>
            <BsGrid1X2 />
          </span>
        <span className='icon icon-close' onClick={() => {
          close();
          setTimeout(() => {
            setIndex(0);
          }, 500);
        }}>
            <AiOutlineClose />
          </span>
      </div>

      <div className='modal-content'>
        <div className='modal-image'>
            <span className='previous' onClick={() => handleArrowClick('previous')}>
              <AiOutlineArrowLeft />
            </span>
          <Img fluid={images[index].node.childImageSharp.fluid} className='feature-image' style={{ maxHeight: '100%', height: '100%', maxWidth: 'auto' }} imgStyle={{ objectFit: 'contain' }} />
          <span className='next' onClick={() => handleArrowClick('next')}>
              <AiOutlineArrowRight />
            </span>
          <span className='indicator'>
              { `${index + 1} / ${images.length}` }
            </span>
        </div>
        <div className={`modal-thumbnails ${isThumbnailsVisible ? 'active' : ''}`}>
          {
            images.map(({ node }, index) => {
              return (
                <div key={index} className='thumbnail-wrapper' onClick={() => setIndex(index)}>
                  <Img fluid={node.childImageSharp.fluid} className='thumbnail' />
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Modal
