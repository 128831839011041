import React, { useState } from "react"
import './aanbod.sass'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Modal from "../modal/modal"
import Layout from "../layout"
import Heading from "../heading/heading"

const cardImageStyle = {
  height: '50rem',
  width: 'auto',
  borderRadius: '2rem'
}

const Aanbod = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [folder, setFolder] = useState();

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: { in: ["buxus/buxus_01.jpg", "ilex/aquifolium/ilex_aq_01.jpg", "osmanthus/osmanthus_01.jpg", "taxus/taxus_01.jpg"] }}) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const openModal = (folder) => {
    setIsModalVisible(true);
    setFolder(folder)
  }

  const closeModal = () => {
    setIsModalVisible(false);
    setTimeout(() => setFolder(undefined), 500);
  }

  return (
    <section id='aanbod' className='aanbod'>
      <Heading text='Aanbod' />

      <div className='aanbod-grid'>
        <div className='ilex'>
          <div className='card'>
            <div className='card-image' onClick={() => openModal('ilex')}>
              <Img fluid={data.allFile.edges.find(edge => edge.node.base.includes('ilex')).node.childImageSharp.fluid} style={cardImageStyle} />
              <span className='overlay'>Ilex</span>
            </div>
          </div>
        </div>
        <div className='osmanthus'>
          <div className='card'>
            <div className='card-image' onClick={() => openModal('osmanthus')}>
              <Img fluid={data.allFile.edges.find(edge => edge.node.base.includes('osmanthus')).node.childImageSharp.fluid} style={cardImageStyle} />
              <span className='overlay'>Osmanthus</span>
            </div>
          </div>
        </div>
        <div className='taxus'>
          <div className='card'>
            <div className='card-image' onClick={() => openModal('taxus')}>
              <Img fluid={data.allFile.edges.find(edge => edge.node.base.includes('taxus')).node.childImageSharp.fluid} style={cardImageStyle} />
              <span className='overlay'>Taxus</span>
            </div>
          </div>
        </div>
        <div className='buxus'>
          <div className='card'>
            <div className='card-image' onClick={() => openModal('buxus')}>
              <Img fluid={data.allFile.edges.find(edge => edge.node.base.includes('buxus')).node.childImageSharp.fluid} style={cardImageStyle} />
              <span className='overlay'>Buxus</span>
            </div>
          </div>
        </div>
      </div>

      <Modal isVisible={isModalVisible} folder={folder} close={closeModal} />
    </section>
  )
}

export default Aanbod
