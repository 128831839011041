import React from "react"
import './heading.sass'

const Heading = ({ text }) => {
  return (
    <div className='heading'>
      <div className='heading-left'>
        <div className='heading-top' />
        <div className='heading-bottom' />
      </div>
      <h1>{ text }</h1>
      <div className='heading-right'>
        <div className='heading-top' />
        <div className='heading-bottom' />
      </div>
    </div>
  )
}

export default Heading;
